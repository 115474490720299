import React, { useState, useEffect, useRef } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Avatar, Divider } from "@mui/material"
import { logOut } from "../../modules/landing/authSlice"
import { ucfirst, getImageURL } from "../../helpers"
import dashboard_icon from "../../assets/images/icons/header/dashboard.svg"
import dashboard_white_icon from "../../assets/images/icons/header/dashboard_white.svg"
import company_icon from "../../assets/images/icons/header/company.svg"
import company_white_icon from "../../assets/images/icons/header/company_white.svg"
import charger_icon from "../../assets/images/icons/header/charger.svg"
import charger_white_icon from "../../assets/images/icons/header/charger_white.svg"
import user_icon from "../../assets/images/icons/header/user.svg"
import user_white_icon from "../../assets/images/icons/header/user_white.svg"
import billings_icon from "../../assets/images/icons/header/billings.svg"
import billings_white_icon from "../../assets/images/icons/header/billings_white.svg"
import coupons_icon from "../../assets/images/icons/header/coupons.svg"
import coupons_white_icon from "../../assets/images/icons/header/coupons_white.svg"
import complaints_icon from "../../assets/images/icons/header/complaints.svg"
import complaints_white_icon from "../../assets/images/icons/header/complaints_white.svg"
import auto_trigger_icon from "../../assets/images/icons/header/auto_trigger_icon.svg"
import auto_trigger_white_icon from "../../assets/images/icons/header/auto_trigger_white_icon.svg"
import "./CmsHeader.css"

const Header = props => {
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname

    const { userRoles, userInfo } = props

    const dashboardHiddenCompanyIds = process.env.REACT_APP_DASHBOARD_HIDDEN_COMPANIES || []
    const isDashboardVisible = !dashboardHiddenCompanyIds?.includes(userInfo?.companyId)

    const [showProfile, setShowProfile] = useState(false)
    const [hoveredIcon, setHoveredIcon] = useState(false)
    const profileRef = useRef(null)
    const dispatch = useDispatch()
    const logo = getImageURL(process.env.REACT_APP_LOGOS, "header")

    const headerSequence = {
        "company-management": [
            { name: "Company Details", path: "company-details", access: "all" },
            {
                name: "CPO",
                path: "cpo",
                access: userInfo.companyGroup === "marketplace",
            },
            {
                name: "clients",
                path: "client",
                access:
                    userInfo.companyGroup === "CPO" ||
                    userInfo.companyGroup === "marketplace" ||
                    userInfo.companyGroup === "CPO_eMSP" ||
                    userInfo.companyGroup === "eMSP",
            },
        ],
        "charger-management": [
            { name: "overview", path: "overview", access: "all" },
            { name: "stations", path: "all-stations", access: "all" },
            { name: "charger", path: "all-chargers", access: "all" },
            { name: "charging-station-logs", path: "charging-station-log", access: "all" },
        ],
        "user-management": [
            { name: "overview", path: "overview", access: "all" },
            {
                name: "ev-owners",
                path: "all-evowners",
                access:
                    userInfo?.companyGroup === "marketplace" ||
                    userInfo?.companyGroup === "CPO_eMSP" ||
                    userInfo?.companyGroup === "eMSP" ||
                    userInfo?.companyGroup === "Fleet",
            },
            { name: "admins", path: "administrator", access: "all" },
        ],
        "billings-and-payments": [
            { name: "overview", path: "overview", access: "all" },
            { name: "charging-session", path: "charging-sessions", access: "all" },
            {
                name: "user-payments",
                path: "user-payments",
                access:
                    userInfo.companyGroup === "marketplace" ||
                    userInfo.companyGroup === "CPO_eMSP" ||
                    userInfo.companyGroup === "eMSP",
            },
            {
                name: "company-payments",
                path: "company-payments",
                access:
                    (userInfo.companyGroup === "CPO_eMSP" ||
                        userInfo.companyGroup === "eMSP" ||
                        userInfo?.companyGroup === "Fleet" ||
                        userInfo?.companyGroup === "marketplace") &&
                    parseInt(userInfo?.country_id) === 101,
            },

            {
                name: "company-payouts",
                path: "company-payouts",
                access:
                    (userInfo.companyGroup !== "eMSP" || userInfo?.companyGroup !== "Fleet") &&
                    parseInt(userInfo?.country_id) === 101,
            },
            {
                name: "license",
                path: "license",
                access:
                    userInfo.companyGroup === "marketplace" ||
                    userInfo.companyGroup === "CPO" ||
                    userInfo.companyGroup === "CPO_eMSP",
            },
            { name: "company-invoicing", path: "company-invoicing", access: userInfo.companyGroup === "marketplace" },
            {
                name: "PnL",
                path: "pnl",
                access: userInfo.companyGroup === "marketplace" || parseInt(userInfo?.companyId) === 16,
            },
        ],
        "complaints-management": [
            { name: "overview", path: "overview", access: "all" },
            { name: "complaints", path: "all-complaints", access: "all" },
        ],
        "auto-trigger": [
            {
                name: "auto-trigger",
                path: "alerts",
                access:
                    userInfo.companyGroup === "marketplace" ||
                    userInfo.companyGroup === "CPO_eMSP" ||
                    userInfo.companyId === 16,
            },
        ],
    }

    const getModuleLandingPath = (module, pathURL) => {
        const moduleSequence = headerSequence?.[module]
        let landingPath = ""
        moduleSequence?.every(el => {
            const isLandingPath = userRoles?.[module]?.[el?.name]?.view
            if (isLandingPath && el?.path && el?.access) {
                if (
                    module === "billings-and-payments" &&
                    el.path === "overview" &&
                    userRoles?.[module]?.["charging-session"]?.view
                ) {
                    landingPath = "charging-sessions"
                } else {
                    landingPath = el.path
                }
                return false
            }
            return true
        })
        return `/${pathURL}/${landingPath}`
    }

    const headerData = [
        isDashboardVisible && {
            title: "Dashboard",
            icon: dashboard_icon,
            activeIcon: dashboard_white_icon,
            modulePath: "dashboard",
            path: "/",
            pathTwo: "/insights",
        },
        userRoles?.["company-management"]?.view && {
            title: "Company Management",
            icon: company_icon,
            activeIcon: company_white_icon,
            modulePath: "company-management",
            path: getModuleLandingPath("company-management", "company-management"),
        },
        userRoles?.["charger-management"]?.view && {
            title: "Charger management",
            icon: charger_icon,
            activeIcon: charger_white_icon,
            modulePath: "charger-management",
            path: getModuleLandingPath("charger-management", "charger-management"),
        },
        userRoles?.["user-management"]?.view && {
            title: "User management",
            icon: user_icon,
            activeIcon: user_white_icon,
            modulePath: "user-management",
            path: getModuleLandingPath("user-management", "user-management"),
        },
        userRoles?.["billings-and-payments"]?.view && {
            title: "Billing & Payments",
            icon: billings_icon,
            activeIcon: billings_white_icon,
            modulePath: "billing",
            path: getModuleLandingPath("billings-and-payments", "billing"),
        },
        userRoles?.["complaints-management"]?.view &&
            userInfo?.companyGroup !== "Host" &&
            userInfo?.companyGroup !== "Investor" && {
                title: "Complaints",
                icon: complaints_icon,
                activeIcon: complaints_white_icon,
                modulePath: "complaints",
                path: getModuleLandingPath("complaints-management", "complaints"),
            },
        userInfo?.companyGroup === "marketplace" &&
            userRoles?.["coupons-management"]?.view && {
                title: "Coupons",
                icon: coupons_icon,
                activeIcon: coupons_white_icon,
                modulePath: "coupon",
                path: "/coupon",
            },
        (userInfo?.companyGroup === "marketplace" ||
            (userInfo?.companyGroup === "CPO_eMSP" && userInfo?.companyId === 16 && userInfo?.countryId === 101)) &&
            userRoles?.["auto-trigger"]?.view && {
                title: "Auto Triggers",
                icon: auto_trigger_icon,
                activeIcon: auto_trigger_white_icon,
                modulePath: "auto-trigger",
                path: getModuleLandingPath("auto-trigger", "auto-trigger"),
            },
    ]

    const isActive = data =>
        (data?.title === "Dashboard" && (path === "/" || path.includes(data?.modulePath))) ||
        (data?.title !== "Dashboard" && path.includes(data?.modulePath))

    useEffect(() => {
        const handleClickOutside = event => {
            if (profileRef.current && !profileRef.current.contains(event.target)) {
                setShowProfile(false)
            }
        }
        document.addEventListener("click", handleClickOutside, true)
        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [showProfile])

    return (
        <div className="header">
            <Link to="/">
                <img src={logo} className="logo__image" alt="logo" />
            </Link>

            <nav>
                <ul>
                    {headerData?.map(data => {
                        const activeOrHover = isActive(data) || hoveredIcon === data?.title
                        return (
                            data && (
                                <li
                                    key={data?.title}
                                    onMouseOver={() => setHoveredIcon(data?.title)}
                                    onMouseOut={() => setHoveredIcon("")}>
                                    <Link
                                        className={activeOrHover ? `active` : ""}
                                        to={data?.redirectPath ? data.redirectPath : data?.path}>
                                        <img
                                            src={activeOrHover ? data?.activeIcon : data?.icon}
                                            alt={`${data?.title} icon`}
                                        />
                                        {data?.title}
                                    </Link>
                                </li>
                            )
                        )
                    })}
                </ul>
            </nav>
            <div className="avatar_container">
                <Avatar onClick={() => setShowProfile(value => !value)} className="avatar">
                    {userInfo.fullname ? ucfirst(userInfo.fullname[0]) : ""}
                </Avatar>
                {showProfile && (
                    <div ref={profileRef} className="profile_modal">
                        <div className="pointer"></div>
                        <Avatar className="modal_avatar">
                            {userInfo?.fullname ? ucfirst(userInfo.fullname[0]) : ""}
                        </Avatar>
                        <div className="profile_modal_name">{userInfo?.fullname ? ucfirst(userInfo.fullname) : ""}</div>
                        <div className="profile_modal_role">{userInfo?.admin_role}</div>
                        <div className="profile_modal_company">{userInfo?.companyName}</div>
                        <Divider className="my_10" />
                        <div className="profile_modal_action_container">
                            <Link
                                className="action profile_modal_edit"
                                to="/profile/my-profile"
                                onClick={() => setShowProfile(false)}>
                                Edit Profile
                            </Link>
                            {(userInfo?.companyGroup === "marketplace" || parseInt(userInfo?.companyId) === 16) &&
                                userRoles?.reports?.view && (
                                    <Link
                                        className="action profile_modal_edit"
                                        to="/reports"
                                        onClick={() => setShowProfile(false)}>
                                        Reports
                                    </Link>
                                )}
                            <div
                                className="action profile_modal_logout"
                                onClick={() => {
                                    dispatch(logOut())
                                    localStorage.removeItem("isUserLoggedIn")
                                    localStorage.removeItem("userInfo")
                                    localStorage.removeItem("userRoles")
                                    localStorage.removeItem("countryId")
                                    navigate("/auth")
                                }}>
                                Logout
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Header
